<template>
  <div class="import-page">
    <el-card>
      <el-row class="row-bg"
        justify="center ">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark">
            <div>1,下载导入模版</div>
            <div class="perfect">根据提示信息完善表格内容</div>
            <el-button class="addBtn">
              <a v-if="this.$route.query.type==1"
                href="https://xinyue-public-new.oss-cn-hangzhou.aliyuncs.com/web_static_resources/import-member.xls">下载模版</a>
              <a v-if="this.$route.query.type==2"
                href="https://xinyue-public-new.oss-cn-hangzhou.aliyuncs.com/web_static_resources/import-external.xls">下载模版</a>
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="row-bg2"
        justify="center">
        <el-col :span="24">
          <div class="grid-content bg-purple-dark grid2">
            <div>2,上传完善后的表格</div>
            <el-upload v-if="this.$route.query.type==1"
              style="width:100%"
              class="upload-demo flexcolumn-row-center"
              drag
              action=''
              :on-change="fileChange"
              :before-upload="beforeUploadFile"
              accept=".xls, .xlsx"
              :http-request="httpRequest">
              <div><i class="iconfont iconxlsx"
                  style="color:#19BE6B"></i></div>
              <el-button style="text-align:center"
                class="addBtn"
                @click="uploadFile">上传文件</el-button>
              <div class="el-upload__text">
                将文件拖拽到此区域进行上传，支持xls、xlsx格式</em>
              </div>
            </el-upload>
            <el-upload v-if="this.$route.query.type==2"
              style="width:100%"
              class="upload-demo flexcolumn-row-center"
              drag
              action=''
              :on-change="fileChange"
              :before-upload="beforeUploadFile"
              accept=".xls, .xlsx"
              :http-request="getExternal">
              <div><i class="iconfont iconxlsx"
                  style="color:#19BE6B"></i></div>
              <el-button style="text-align:center"
                class="addBtn"
                @click="uploadFile">上传文件</el-button>
              <div class="el-upload__text">
                将文件拖拽到此区域进行上传，支持xls、xlsx格式</em>
              </div>
            </el-upload>
          </div>
        </el-col>
      </el-row>
      <!-- <el-form>
        <el-form-item class="flex-column-all-center">
        <el-button>取消</el-button>
        <el-button type="primary" class="buttonColor" @click="uploadFile">导入</el-button>
      </el-form-item>
      </el-form> -->
    </el-card>
  </div>
</template>
<script>
import request from "@request/api/index";
export default {
  components: {},
  props: {},
  watch: {},
  data() {
    return {
      fileList: [],
      excelfile: {},
      errorData: [],
      type: 1,
    };
  },
  created() {
    this.type = this.$route.query.type;
  },

  methods: {
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx" && extension !== "xls") {
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 50000) {
        this.$message.warning("文件大小不得超过50k");
      }
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${
          files.length + fileList.length
        } 个`
      );
    },
    async httpRequest(file) {
      try {
        const params = {
          excelfile: file.file,
        };
        await request.organization.requestBatchImport(params).then((res) => {
          if (res.data.code == 1000) {
            this.$router.push({
              path: `/importSuccess?type=${this.type}`,
            });
          } else if (res.data.code == 3144) {
            this.errorData = res.data.data;
            this.$router.push({
              path: "/importError",
              query: {
                errorData: this.errorData,
                type: this.$route.query.type,
              },
            });
          }
        });
      } catch (e) {
        console.error(e);
        this.$message.error("导入超时，请稍后尝试!");
      }
    },
    async getExternal(file) {
      try {
        const params = {
          excelfile: file.file,
        };
        await request.organization
          .reuqestExternalBatchImport(params)
          .then((res) => {
            if (res.data.code == 1000) {
              this.$router.push({
                path: `/importSuccess?type=${this.type}`,
              });
            } else if (res.data.code == 3144) {
              console.log("3144");
              this.errorData = res.data.data;
              this.$router.push({
                path: "/importError",
                query: {
                  errorData: this.errorData,
                  type: this.$route.query.type,
                },
              });
            }
          });
      } catch (e) {
        console.log(e);
        this.$message.error("导入超时，请稍后尝试!");
      }
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      // console.log('fileList',fileList)
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      this.$message.success("文件上传成功");
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$message.error("文件上传失败");
    },
    uploadFile() {},
  },
};
</script>
<style lang="scss" scoped>
.import-page {
  padding-top: 30px;
  .el-card {
    padding: 15px 100px 15px 100px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    background-color: #f8f9fb;
  }
  .row-bg {
    padding: 20px 0;
    border-radius: 6px;
    background-color: #f9fafc;
    padding: 30px;
  }
  .row-bg2 {
    border-radius: 6px;
    padding: 20px 0;
    margin-top: 50px;
  }
  .perfect {
    margin: 15px 10px 15px 0;
  }
  .grid2 {
    padding: 30px;
  }
  .addBtn {
    color: #3468fe;
    border: 1px solid #3468fe;
    background-color: #ffffff;
  }
  .iconfont {
    color: #b4bbc5;
    font-size: 52px;
  }
}
</style>
